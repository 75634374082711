'use client';
import config from '@/config';
import logger from '@/lib/logger';

export default function AppError(props: { error: unknown }) {
  logger.error('Rendering error', { error: props.error });
  return (
    <div className="stack flex-auto items-center justify-center bg-contrast-0">
      <div className="text-lg font-semibold">Uh oh. Something went wrong.</div>
      <p className="text-sm text-contrast-700">
        The team's been notified, and we're looking into it. In the meantime,
        you can try refreshing.
      </p>

      {config.env.isDevelopment && (
        <div className="border border-red-500 rounded font-mono text-sm px-4 py-2 whitespace-pre mt-8 max-w-full mx-12 overflow-auto">
          {errorToString(props.error)}
        </div>
      )}
    </div>
  );
}

function errorToString(error: unknown) {
  if (!(error instanceof Error)) {
    return JSON.stringify(error);
  }
  return error.stack ?? error.message ?? error.toString();
}
